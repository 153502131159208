<script setup lang="ts">
import {
  TooltipArrow,
  TooltipContent,
  type TooltipContentEmits,
  type TooltipContentProps,
  TooltipPortal,
  useForwardPropsEmits,
} from 'reka-ui';
import { type HTMLAttributes, computed } from 'vue';

import { cn } from '@/lib/utils';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<TooltipContentProps & { class?: HTMLAttributes['class'] }>(),
  {
    sideOffset: 4,
    class: '',
  },
);

const emits = defineEmits<TooltipContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <TooltipPortal>
    <TooltipContent
      v-bind="{ ...forwarded, ...$attrs }"
      :class="
        cn(
          'z-50 max-w-[300px] overflow-hidden rounded-md bg-primary-900 p-4' +
            ' text-sm text-invert-1000 shadow-md animate-in fade-in-0 zoom-in-95' +
            ' data-[state=closed]:animate-out data-[state=closed]:fade-out-0' +
            ' data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2' +
            ' data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2' +
            ' data-[side=top]:slide-in-from-bottom-2',
          props.class,
        )
      "
    >
      <slot />
      <TooltipArrow class="fill-primary-900" :width="12" />
    </TooltipContent>
  </TooltipPortal>
</template>
