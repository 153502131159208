<script setup lang="ts">
import { ToastAction, type ToastActionProps } from 'reka-ui';
import { computed, type HTMLAttributes } from 'vue';

import { cn } from '@/lib/utils';

const props = defineProps<ToastActionProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <ToastAction
    v-bind="delegatedProps"
    :class="
      cn(
        'ml-10 flex items-center gap-1 text-body-s font-semibold underline underline-offset-[6px]',
        props.class,
      )
    "
  >
    <slot />
  </ToastAction>
</template>
