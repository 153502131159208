<script setup lang="ts">
import { computed, type HTMLAttributes } from 'vue';
import { RouterLink } from 'vue-router';

import { linkVariants, type LinkVariants } from '@/components/ui/link/index.ts';
import { cn } from '@/lib/utils.ts';

type RouterLinkProps = InstanceType<typeof RouterLink>['$props'];

interface Props {
  variant?: LinkVariants['variant'];
  size?: LinkVariants['size'];
  class?: HTMLAttributes['class'];
  to: RouterLinkProps['to'];
  isExternal?: boolean;
  hasUnderline?: boolean;
  isActive?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'default',
  size: 'default',
  class: '',
  isExternal: false,
  hasUnderline: false,
  isActive: false,
});

const rawLink = computed(() => {
  return typeof props.to === 'string' ? props.to : '';
});

const underlineClass = computed(() => {
  return props.hasUnderline ? 'underline' : '';
});
</script>

<template>
  <template v-if="isExternal">
    <a
      :class="cn(linkVariants({ variant, size }), props.class, underlineClass)"
      :href="rawLink"
      v-bind="$attrs"
      ><slot
    /></a>
  </template>
  <template v-else>
    <RouterLink
      :class="cn(linkVariants({ variant, size }), props.class, underlineClass)"
      :to="to"
      v-bind="$attrs"
      ><slot
    /></RouterLink>
  </template>
</template>
