<script setup lang="ts">
import { ToastTitle, type ToastTitleProps } from 'reka-ui';
import { computed, type HTMLAttributes } from 'vue';

import { cn } from '@/lib/utils';

const props = defineProps<ToastTitleProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <ToastTitle
    v-bind="delegatedProps"
    :class="cn('flex items-center justify-between text-body-m font-semibold', props.class)"
  >
    <slot />
  </ToastTitle>
</template>
