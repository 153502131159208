<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { GoogleLogin, type CallbackTypes } from 'vue3-google-login';
import { z } from 'zod';

import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader, TDialogContentBodyCentered, TDialogClose } from '@/components/ui/dialog';
import { FormControl, FormItem, FormMessage, FormField } from '@/components/ui/form';
import TInput from '@/components/ui/input/TInput.vue';
import { TPasswordInput } from '@/components/ui/password-input';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useAuthDialog } from '@/hooks/auth/use-auth-dialog.ts';
import { useResetPasswordDialog } from '@/hooks/auth/use-reset-password-dialog.ts';
import ApiError from '@/models/api/api-error.ts';
import { useLoginGoogleMutation } from '@/queries/auth/use-login-google-mutation.ts';
import { useLoginMutation } from '@/queries/auth/use-login-mutation.ts';
import { useUserBuyOrdersMutation } from '@/queries/users/use-user-buy-orders';
import { AuthDialogState } from '@/types/auth/auth-dialog-state-type.ts';
import { ResetPasswordDialogState } from '@/types/auth/reset-password-dialog.ts';

import WarningHexagonIcon from '~icons/iconoir/warning-hexagon';

const zodSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
});

const formSchema = toTypedSchema(zodSchema);

const breakpoints = useBreakpoints(breakpointsTailwind);
const lgBreakpoint = breakpoints.greaterOrEqual('lg');

const { handleSubmit, isSubmitting, meta } = useForm({
  validationSchema: formSchema,
});

const isFormValid = computed(() => meta.value.valid);
const isButtonDisabled = computed(() => !isFormValid.value || isSubmitting.value);

const errorMessage = ref('');

const onSubmit = handleSubmit((values) => {
  return login(values.email, values.password);
});

const { mutateAsync: userBuyOrdersMutation } = useUserBuyOrdersMutation();
const router = useRouter();

const onLoginSuccess = async (userId: string) => {
  closeDialog();

  // TODO: improve dependant dialogs
  // Some ideas:
  // https://dev.to/neophen/type-safe-promise-based-vue-3-modals-thatll-have-you-popping-with-joy-4299
  if (authCallback.value) {
    authCallback.value();
    return;
  }
  const buyOrders = await userBuyOrdersMutation(userId);
  if (buyOrders.length > 0) {
    await router.push('/portfolio');
  }
};

const onLoginError = (error: ApiError) => {
  errorMessage.value = error.data.message;
};

const { mutateAsync: loginMutation, isPending: isLoginPending } = useLoginMutation();

const { mutateAsync: loginGoogleMutation } = useLoginGoogleMutation();

const login = async (email: string, password: string) => {
  try {
    const { id: userId } = await loginMutation({ email, password });
    await onLoginSuccess(userId);
  } catch (e) {
    onLoginError(e as ApiError);
  }
};

const googleCallback = async (response: Parameters<CallbackTypes.CredentialCallback>[0]) => {
  try {
    const { id: userId } = await loginGoogleMutation({ credential: response.credential });
    await onLoginSuccess(userId);
  } catch (e) {
    onLoginError(e as ApiError);
  }
};

const { changeModalState, closeDialog, authCallback } = useAuthDialog();

const setRegisterState = (): void => {
  changeModalState(AuthDialogState.Register);
};

const { openDialog: openResetPasswordDialog } = useResetPasswordDialog();

const resetPassword = () => {
  closeDialog();
  openResetPasswordDialog(ResetPasswordDialogState.sendEmail);
};
</script>

<template>
  <TDialogHeader class="flex flex-row gap-4 bg-white lg:bg-beige-100">
    <div class="h-full w-fit content-center text-primary-900">
      <LocaleSelector />
    </div>
    <div class="ml-auto mr-10 flex items-center justify-center gap-4 md:mr-0">
      <p class="hidden text-sm font-semibold text-primary-700 lg:block">
        {{ $t('dialog.login.noAccount') }}
      </p>
      <TButton variant="secondaryLight" size="sm" @click="setRegisterState">{{
        $t('dialog.login.createAccount')
      }}</TButton>
    </div>
  </TDialogHeader>
  <TDialogClose v-if="!lgBreakpoint" @close="closeDialog" />
  <TDialogContentBodyCentered class="gap-3">
    <h4 class="mb-7 font-bold">{{ $t('dialog.login.title') }}</h4>
    <div class="mx-auto">
      <GoogleLogin
        :callback="googleCallback"
        :button-config="{
          type: 'icon',
          logo_alignment: 'center',
          size: 'large',
          shape: 'circle',
        }"
      />
    </div>
    <div class="flex items-center justify-between gap-2">
      <div class="h-[1px] w-[1px] flex-1 bg-primary-400" />
      <p class="text-sm font-medium text-primary-500">Or</p>
      <div class="h-[1px] w-[1px] flex-1 bg-primary-400" />
    </div>
    <form class="flex flex-col gap-4" @submit="onSubmit">
      <FormField v-slot="{ componentField }" name="email">
        <FormItem>
          <FormControl>
            <TInput
              type="email"
              placeholder="Email"
              v-bind="componentField"
              autocomplete="username"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="password">
        <FormItem>
          <FormControl>
            <TPasswordInput v-bind="componentField" autocomplete="current-password" />
          </FormControl>
          <FormMessage />
          <p class="cursor-pointer text-xs text-primary-600" @click="resetPassword">
            {{ $t('dialog.login.forgotPassword') }}
          </p>
        </FormItem>
      </FormField>
      <p v-if="errorMessage" class="text-sm text-danger-500">
        <WarningHexagonIcon class="mr-2 inline-block" />{{ errorMessage }}
      </p>
      <TButton class="mt-7" type="submit" :disabled="isButtonDisabled">
        <TWaveLoader v-if="isLoginPending" size="sm" class="bg-white" />
        <p v-else>Login</p>
      </TButton>
    </form>
  </TDialogContentBodyCentered>
</template>
