<script setup lang="ts">
import { DialogClose, type DialogCloseProps } from 'reka-ui';

import XmarkIcon from '~icons/iconoir/xmark';

const props = defineProps<DialogCloseProps>();
const emit = defineEmits(['close']);
</script>

<template>
  <DialogClose v-bind="props" as-child>
    <slot>
      <button
        class="absolute right-8 top-4 flex h-7 w-7 items-center justify-center rounded-full bg-beige-500 text-invert-400 transition hover:shadow-xl lg:right-2.5 lg:top-2.5"
        :aria-label="$t('common.close')"
        @click="emit('close')"
      >
        <XmarkIcon class="h-5.5 w-5.5" />
      </button>
    </slot>
  </DialogClose>
</template>
