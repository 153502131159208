<script setup lang="ts">
import { ref } from 'vue';

import {
  Combobox,
  ComboboxAnchor,
  ComboboxInput,
  ComboboxList,
  ComboboxEmpty,
  ComboboxGroup,
  ComboboxItem,
} from '@/components/ui/combobox';
import { TWaveLoader } from '@/components/ui/wave-loader';

interface Props<T extends string> {
  modelValue?: T;
  items: { value: number; label: string }[];
  isLoading?: boolean;
  emptyMessage?: string;
  placeholder?: string;
  disabled?: boolean;
}

withDefaults(defineProps<Props<string>>(), {
  isLoading: false,
  emptyMessage: 'No items.',
  placeholder: 'Address',
  disabled: false,
  modelValue: '',
});

const emit = defineEmits<{
  (e: 'update:autoCompleteValue', value: number): void;
}>();

const searchValue = ref('');

const onSelectItem = (option: { value: number; label: string }) => {
  emit('update:autoCompleteValue', option.value);
};
</script>

<template>
  <div class="flex items-center">
    <Combobox class="flex flex-1" :ignore-filter="true">
      <ComboboxAnchor>
        <ComboboxInput
          v-model="searchValue"
          :display-value="() => modelValue"
          :placeholder
          :disabled
        />
      </ComboboxAnchor>
      <ComboboxList class="w-[--reka-popover-trigger-width] bg-white p-0">
        <ComboboxEmpty>
          <template v-if="isLoading">
            <TWaveLoader size="sm" />
          </template>
          <template v-else>
            {{ emptyMessage }}
          </template>
        </ComboboxEmpty>

        <ComboboxGroup>
          <ComboboxItem
            v-for="option in items"
            :key="option.value"
            :value="option.value"
            @select="onSelectItem(option)"
          >
            {{ option.label }}
          </ComboboxItem>
        </ComboboxGroup>
      </ComboboxList>
    </Combobox>
  </div>
</template>
