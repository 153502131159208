export const API_QUERY_KEY_USER_ME = 'user_me';
export const API_QUERY_KEY_PRICES_VOLATILITY = 'prices_volatility';
export const API_QUERY_KEY_PRICES_ASSET = 'prices_asset';
export const API_QUERY_KEY_ASSET_SIMULATION = 'asset_simulation';
export const API_QUERY_KEY_PRICES_CORRELATION = 'prices_correlation';
export const API_QUERY_KEY_IMPACT = 'impact';
export const API_QUERY_KEY_MARKET_INFO = 'market_info';
export const API_QUERY_KEY_ASSET_INVEST = 'asset_invest';
export const API_QUERY_KEY_ADDRESS_AUTOCOMPLETE = 'prices_correlation';
export const API_QUERY_KEY_USER_KYC = 'user_kyc';
export const API_QUERY_KEY_USER_TRANSACTIONS = 'user_transactions';
export const API_QUERY_KEY_USER_PORTFOLIO = 'user_portfolio';
export const API_QUERY_KEY_PORTFOLIO_HISTORY = 'user_portfolio_history';
export const API_QUERY_KEY_USER_BUY_ORDERS = 'user_buy_orders';
export const API_QUERY_KEY_GET_ORDER = 'get_order';
