<script setup lang="ts">
import {
  TooltipRoot,
  type TooltipRootEmits,
  type TooltipRootProps,
  useForwardPropsEmits,
} from 'reka-ui';
import { provide, ref } from 'vue';

const props = defineProps<TooltipRootProps>();
const emits = defineEmits<TooltipRootEmits>();

const providedOpen = ref<boolean>(props.open);

const forwarded = useForwardPropsEmits(props, emits);

const setOpen = (value: boolean) => {
  providedOpen.value = value;
};

provide('tooltipTriggerProvide', {
  setOpen,
});
</script>

<template>
  <TooltipRoot
    v-bind="forwarded"
    :open="providedOpen"
    disable-closing-trigger
    @update:open="setOpen"
  >
    <slot />
  </TooltipRoot>
</template>
